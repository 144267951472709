<template>
    <investors-module-component/>
</template>

<script>
    import InvestorsModuleComponent from "@/components/admin/InvestorsModuleComponent";
    
    export default {
        name: "InvestorsModule",
        title: "BackOffice de Inversionistas | Private Equity Baja",
        components: {InvestorsModuleComponent}
    }
</script>

<style scoped>

</style>