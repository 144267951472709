<template>
  <div>
    <v-text-field
        v-model="cmpValue"
        v-bind:label="label"
        v-bind="properties"
        maxlength="13"

        v-on:blur="$emit('blur')"
        v-on:change="$emit('change')"
        v-on:click="$emit('click')"
        v-on:focus="$emit('focus')"
        v-on:keydown="$emit('keydown')"
        v-on:mousedown="$emit('mousedown')"
        v-on:mouseup="$emit('mouseup')"
        ref="ref"
    ></v-text-field>
  </div>
</template>

<script>

export default {
  name: "InputMoney",
  props: {
    value: {
      type: [String, Number],
      default: "0",
    },
    label: {
      type: String,
      default: "",
    },
    properties: {
      type: Object,
      default: function() {
        return {};
      },
    },
    options: {
      type: Object,
      default: function() {
        return {
          locale: "pt-BR",
          empty: null,
        };
      },
    },
  },

  computed: {
    cmpValue: {
      get: function() {
        return this.init();
      },
      set: function(newValue) {
        this.$emit("input", this.init(newValue));
      },
    },
  },

  mounted() {
    this.$store.state.valueIntern = this.value;
  },

  methods:{
    init(value){
      value = value?String(value):String(this.$store.state.valueIntern);

      if(value.indexOf('.')==-1){
        value = `${value}.00`;
      }
      /*else{
        const split = value.split('.');
        const str = split[1];
        if(str.length>2){
          value = `${split[0]}.${str.substring(0,2)}`;
        }
      }*/

      if (!value || value == '0.0') {
        value = '';
        this.$emit('update:modelValue', 0);
        return
      }

      var val = Number( (value.replace(/[^\d-]/g, '') || 0) / 100 )

      // Add money mask
      var signal = value.indexOf('-') === 0 ? '-' : '';
      value = this.formatMoney(val, signal);
      // this.value = window.$money(val, signal)

      this.$store.state.valueIntern = this.formatMoney(val, signal);
      console.log(this.$store.state.valueIntern);

      this.$emit('update:modelValue', parseFloat(this.$store.state.valueIntern.replace(/,/g, '')));

      this.$store.state.numberFormat = parseFloat(this.$store.state.valueIntern.replace(/,/g, ''));

      // Update v-model
      return value;

      // update cursor position
      /*if (value.length > 1) {
        this.fixSelection($event.srcElement, this.value.length - value.length)
      }*/
    },

    formatMoney (val, signal = '') {
      if (!val)
        return signal + '0.00'

      if (typeof val === 'string')
        val = Number(val)

      return val.toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, '$&,')
          .replace(/\.(\d\d)$/g, '.$1')
    },

    fixSelection (el, diff) {
      let position = el.selectionEnd + diff
      const digit = el.value[position - 1]

      while (position && position < el.value.length && el.value.charAt(position - 1) !== digit) {
        position++
      }

      if (el === document.activeElement) {
        setTimeout(function () {
          el.setSelectionRange(position, position)
        }, 0)
      }
    },

    moneyMask ($event) {
      var value = String(this.value)

      // Add - signal into value
      if ($event.data === '-') value = '-' + value.replace(/-/g, '')
      else if ($event.data === '+') value = value.replace(/-/g, '')

      if (!value || value === '0.0') {
        this.value = ''
        this.$emit('update:modelValue', null)
        return
      }

      var val = Number( (value.replace(/[^\d-]/g, '') || 0) / 100 )

      // Add money mask
      var signal = value.indexOf('-') === 0 ? '-' : ''
      this.value = this.formatMoney(val, signal)
      // this.value = window.$money(val, signal)

      // Update v-model
      this.$emit('update:modelValue', this.value ? Number(this.value.replace(/,/g, '')) : null)

      // update cursor position
      if (value.length > 1) {
        this.fixSelection($event.srcElement, this.value.length - value.length)
      }
    }
  },
}
</script>

<style scoped>

</style>