/***********************************/
// Capa de Servicios para el modulo.

import axios from "axios";
import store from "@/store/store.js";

export default new class {

    #baseUrl = null;
    errormsg = '';
    message = null;
    header = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        }
    };

    constructor() {
        // Inicializar campos y propiedades.
        this.#baseUrl = store.getters.getBaseURL;
    }

    async getAllRecords() {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.get(this.#baseUrl + "/projects-events-investor", this.header);
            // console.log('response: ', response);
            if (response.data.success) {
                // console.log("response.data:", response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getViewList(id) {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.get(`${this.#baseUrl}/view-list/projects-events-investor?id=${id}`, this.header);
            // console.log('response: ', response);
            if (response.data.success) {
                // console.log("records:", response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getViewListEvents(id) {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.get(`${this.#baseUrl}/view-list-events/projects-events-investor?id=${id}`, this.header);
            // console.log('response: ', response);
            if (response.data.success) {
                // console.log("records:", response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getRecord(id) {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.get(`${this.#baseUrl}/projects-events-investor/${id}`, this.header);
            if (response.data.success) {
                // console.log("records:", response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async addRecord(formData) {
        this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
        let result = null;
        // let projectsList = formData.projects;
        let recordData = new FormData();
        recordData.append("id", formData.id);
        recordData.append("project_id", formData.project_id);
        recordData.append("event_id", formData.event_id);
        recordData.append("project_investor_id", formData.project_investor_id);
        recordData.append("investment_amount", '0');
        recordData.append("investment_income", '0');
        recordData.append("expense", '0');
        recordData.append("utility", '0');
        recordData.append("perc_utility", '0');
        try {
            let response = await axios.post(this.#baseUrl + "/projects-events-investor", recordData, this.header);
            if (response.data.success) {
                let data = response.data;
                result = data;
            } else {
                result = null;
            }
        } catch (error) {
            result = this.handleError(error);
        }
        return result;
    }

    // Pushes posts to the server when called.
    async updateRecord(formData) {
        this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
        let result = null;
        let recordData = new FormData();
        recordData.append("id", formData.id);
        //recordData.append("project_id", formData.project_id);
        //recordData.append("event_id", formData.event_id);
        //recordData.append("project_investor_id", formData.project_investor_id);
        recordData.append("investment_amount", formData.amount);
        recordData.append("investment_income", formData.income);
        recordData.append("expense", formData.expense);
        recordData.append("utility", formData.utility);
        recordData.append("perc_utility", formData.perc_utility);
        recordData.append('_method', 'put');
        try {
            let response = await axios.post(`${this.#baseUrl}/projects-events-investor/${formData.id}`, recordData, this.header);
            if (response.data.success) {
                let data = response.data;
                // let investor_id = data.id;
                // Sincroniza las plataformas
                /*let projectIds = [];
                projectsList.forEach(item => {
                    projectIds.push(item.id);
                });
                this.registerProjects(investor_id, projectIds);*/
                result = data;
            } else {
                result = null;
            }
        } catch (error) {
            result = this.handleError(error);
        }
        return result;
    }

    async deleteRecord(id) {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.delete(`${this.#baseUrl}/projects-events-investor/${id}`, this.header);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    handleError(error) {
        let valueError;
        if (error.response) {
            valueError = error.response.data.messaje;
        } else if (error.request) {
            valueError = error.request.data;
        } else {
            // Something happened in setting up the request and triggered an Error
            valueError = error.message;
            // console.log('Error', error.message);
        }
        // console.log(error.config);
        return valueError;
    }
}
