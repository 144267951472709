<template>
    <v-container fill-height fluid text-xs-center>
        <v-card class="fill-height container--fluid" width="100vw" flat outlined>
            <v-data-table
                :headers="headers"
                :items="investors"
                :items-per-page="-1"
                :search="searchInvestor"
                :loading="loadingData"
                :custom-filter="customSearch"
                item-key="id"
                sort-by="id"
                disable-sort
                no-data-text="No hay datos para mostrar..."
                loading-text="Cargando los datos, por favor espere."
                class="elevation-1"
                :footer-props="{
                      showFirstLastPage: false,
                      disableItemsPerPage: false,
                      itemsPerPageAllText: 'Todas',
                      itemsPerPageText: 'Filas por página:'
                    }">
                <template v-slot:top>
                    <v-toolbar flat class="align-center">
                        <v-toolbar-title class="text-md-h6">Proyectos y Eventos de los Inversores</v-toolbar-title>
                        <v-divider
                            class="mx-4"
                            inset
                            vertical
                        ></v-divider>
                        <v-spacer></v-spacer>
                        <v-responsive max-width="300" height="45" class="hidden-sm-and-down">
                            <v-text-field
                                id="findtext-investor"
                                label="Buscar.."
                                name="findinvestor"
                                outlined
                                dense
                                single-line
                                clearable
                                background-color="white"
                                hide-details
                                v-model="searchInvestor"
                                class="white--text mt-0 pt-0 mr-2"
                                prepend-inner-icon="mdi-magnify"></v-text-field>
                        </v-responsive>
                        <v-btn @click="getInvestors"
                               :loading="loadingData"
                               elevation="0"
                               :small="$vuetify.breakpoint.smAndDown"
                               class="mb-2 mr-2"
                        >
                          <v-icon>mdi-reload</v-icon>
                        </v-btn>
                        <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown"
                                  v-model="dialog"
                                  max-width="500px"
                                  persistent>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn :small="$vuetify.breakpoint.smAndDown"
                                       color="primary caption"
                                       dark
                                       depressed
                                       :loading="loadingData"
                                       elevation="0"
                                       class="mb-2"
                                       v-bind="attrs"
                                       v-on="on"
                                       @click="newItem">
                                    Adicionar
                                </v-btn>
                            </template>
                            <v-card>
                                <v-form
                                    ref="form"
                                    v-model="valid"
                                    lazy-validation>
                                    <v-card-title class="text-h6 primary">
                                        <span class="white--text headline">{{ formTitle }}</span>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-select
                                                        v-model="editedItem.user"
                                                        :items="usersFilteredList"
                                                        :item-text="getItemText"
                                                        item-value="id"
                                                        :disabled="loadingSave"
                                                        item-color="primary"
                                                        return-object
                                                        :rules="[v => !!v || 'Una Inversionista es obligatorio']"
                                                        required
                                                        clearable
                                                        label="Usuario Inversionista">
                                                        <template v-slot:item="slotProps">
                                                            <div class="fill-height container--fluid justify-start">
                                                                <v-row align="center" justify="start">
                                                                    <v-col cols="3">
                                                                        <v-avatar
                                                                            class="profile"
                                                                            color="transparent"
                                                                            size="64">
                                                                            <v-icon x-large>mdi-account</v-icon>
                                                                        </v-avatar>
                                                                    </v-col>
                                                                    <v-col cols="9">
                                                                        <span class="text-caption font-weight-medium"
                                                                              style="width: 100%">
                                                                            {{
                                                                                slotProps.item.name
                                                                            }} {{ slotProps.item.last_name }}
                                                                        </span>
                                                                    </v-col>
                                                                </v-row>
                                                            </div>
                                                        </template>
                                                    </v-select>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn :disabled="loadingSave"
                                               @click="close">
                                            Cancelar
                                        </v-btn>
                                        <v-btn :disabled="!valid"
                                               :loading="loadingSave"
                                               depressed
                                               color="primary darken-1 caption"
                                               @click="save">
                                            <v-icon>mdi-content-save</v-icon>
                                            Guardar
                                        </v-btn>
                                    </v-card-actions>
                                </v-form>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDelete" width="auto">
                            <v-card>
                                <v-card-title class="text-subtitle-1">¿Est&aacute;s seguro que desea eliminar esta asignaci&oacute;n?
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn :disabled="loadingSave" @click="closeDelete">Cancelar</v-btn>
                                    <v-btn :loading="loadingSave" color="primary darken-1 caption" depressed @click="deleteItemConfirm">Proceder</v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDeleteEvent" max-width="500px">
                            <v-card>
                                <v-card-title class="text-h6 headline">
                                    ¿Desea eliminar este Evento de la Lista?
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn :disabled="loadingSave" @click="closeDialogDeleteEvent()">
                                        Cancelar
                                    </v-btn>
                                    <v-btn :loading="loadingSave" color="primary darken-1 caption" depressed @click="deleteItemConfirmEvent()">
                                        Proceder
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDeleteProject" max-width="500px">
                            <v-card>
                                <v-card-title class="text-h6 headline">
                                    ¿Desea eliminar este Proyecto de la Lista?
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn :disabled="loadingSave" @click="closeDialogDeleteProject()">
                                        Cancelar
                                    </v-btn>
                                    <v-btn :loading="loadingSave" color="primary darken-1 caption" depressed @click="deleteItemConfirmProject()">
                                        Proceder
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDeleteProjectEvent" max-width="500px">
                            <v-card>
                                <v-card-title class="text-h6 headline">
                                    ¿Desea eliminar este Evento del Proyecto?
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn :disabled="loadingSave" @click="closeDialogDeleteProjectEvent()">
                                        Cancelar
                                    </v-btn>
                                    <v-btn :loading="loadingSave" color="primary darken-1" depressed @click="deleteItemConfirmProjectEvent()">
                                        Proceder
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            :fullscreen="$vuetify.breakpoint.smAndDown"
                            v-model="dialogEvents"
                            persistent
                            width="1000">
                            <v-card>
                                <v-toolbar dark color="primary" height="60">
                                    <v-btn
                                        class="hidden-sm-and-down"
                                        icon
                                        dark
                                        @click="closeDialogEvents()">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                    <v-toolbar-title>Asignaci&oacute;n de Eventos</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-toolbar-items>
                                        <v-btn dark
                                               text
                                               @click="closeDialogEvents()">
                                            Cerrar
                                        </v-btn>
                                    </v-toolbar-items>
                                </v-toolbar>
                                <v-container fill-height bottom-gradient fluid class="ma-0">
                                    <v-row>
                                        <v-col>
                                            <div class="fill-height">
                                                <v-simple-table dense fixed-header height="250px">
                                                    <template v-slot:default>
                                                        <thead>
                                                        <tr>
                                                            <th class="text-left">
                                                            </th>
                                                            <th class="text-left">
                                                                Nombre del Evento
                                                            </th>
                                                            <th class="text-left">
                                                                Fecha del Evento
                                                            </th>
                                                            <th class="text-left">
                                                                Monto de Inversi&oacute;n
                                                            </th>
                                                            <th class="text-left">
                                                                Inversión en Puntos
                                                            </th>
                                                            <th class="text-left">
                                                                Ingreso del Evento
                                                            </th>
                                                            <th class="text-left">
                                                                Gasto del Evento
                                                            </th>
                                                            <th class="text-left">
                                                                Utilidad
                                                            </th>
                                                            <th class="text-left">
                                                                Acciones
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr
                                                            v-for="event in editedItem.events"
                                                            :key="event.id">
                                                            <td>
                                                                <v-avatar color="#ded06f" size="40" class="my-1">
                                                                    <v-img
                                                                        :src="(event.image_url !== null ||
                                                                        event.image_url !== '') ?
                                                                        event.image_url :
                                                                        require('@/assets/images/no-image.jpg')">
                                                                    </v-img>
                                                                </v-avatar>
                                                            </td>
                                                            <td>{{ event.name }}</td>
                                                            <td>{{ formatDate(event.start_date) }}</td>
                                                            <td>{{ event.pivot.investment_amount | toCurrency }}</td>
                                                            <td>{{ event.pivot.investment_points | toTwoDecimal }}</td>
                                                            <td>{{ event.pivot.investment_income | toCurrency }}</td>
                                                            <td>{{ event.pivot.expense | toCurrency }}</td>
                                                            <td>{{ event.pivot.utility | toCurrency }}</td>
                                                            <td style="width:auto;">
                                                                <v-tooltip bottom>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-icon
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                            small
                                                                            class="mr-2"
                                                                            @click="editEvent(event)">
                                                                            mdi-currency-usd
                                                                        </v-icon>
                                                                    </template>
                                                                    <span>Datos de la Inversi&oacute;n</span>
                                                                </v-tooltip>
                                                                <v-tooltip bottom>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-icon
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                            small
                                                                            class="mr-2"
                                                                            @click="deleteEvent(event)">
                                                                            mdi-delete
                                                                        </v-icon>
                                                                    </template>
                                                                    <span>Eliminar evento</span>
                                                                </v-tooltip>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <div class="container mt-3 pt-0">
                                    <v-row dense>
                                        <v-col cols="12">
                                            <v-select
                                                v-model="selectedEvent"
                                                :items="eventFilteredList"
                                                clearable
                                                :disabled="loadingSave"
                                                item-text="name"
                                                item-value="id"
                                                return-object
                                                placeholder="Seleccione un evento"
                                                item-color="primary"
                                                persistent-hint>
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <div>
                                        <div class="py-3">
                                            <v-btn
                                                :loading="loadingSave"
                                                color="primary caption"
                                                depressed
                                                @click="registerEvent()"
                                                :disabled="selectedEvent == null">
                                                Agregar Evento
                                            </v-btn>
                                        </div>
                                    </div>
                                </div>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogUpdateEvent" max-width="400px" persistent>
                            <v-card>
                                <v-toolbar dark color="primary">
                                    <v-btn
                                        class="hidden-sm-and-down"
                                        icon
                                        dark
                                        @click="closeDialogUpdateEvent()">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                    <v-toolbar-title>Datos de la Inversi&oacute;n 1</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-toolbar-items>
                                        <v-btn dark
                                               text
                                               @click="closeDialogUpdateEvent()">
                                            Cerrar
                                        </v-btn>
                                    </v-toolbar-items>
                                </v-toolbar>
                                <v-container class="container--fluid mt-3">
                                    <v-row dense>
                                        <v-col cols="12">
                                            <v-text-field-money
                                                v-model.number="investor_event.investment_amount"
                                                :disabled="loadingSave"
                                                label="Inversión del Evento"
                                                v-bind:properties="{
                                                                dense: true,
                                                                prefix: '$',
                                                                readonly: false,
                                                                disabled: false,
                                                                outlined: false,
                                                                clearable: false,
                                                                placeholder: '',
                                                              }"
                                                v-bind:options="{
                                                            locale: 'es-MX',
                                                            length: 11,
                                                            precision: 2,
                                                            empty: null,
                                                          }"
                                            />
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field-money
                                                v-model.number="investor_event.investment_points"
                                                label="Inversión en Puntos"
                                                :disabled="loadingSave"
                                                v-bind:properties="{
                                                                dense: true,
                                                                prefix: '',
                                                                suffix: 'puntos',
                                                                readonly: false,
                                                                disabled: false,
                                                                outlined: false,
                                                                clearable: false,
                                                                placeholder: '',
                                                              }"
                                                v-bind:options="{
                                                            locale: 'es-MX',
                                                            length: 11,
                                                            precision: 2,
                                                            empty: null,
                                                          }"
                                            />
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field-money
                                                v-model.number="investor_event.investment_income"
                                                :disabled="loadingSave"
                                                label="Ingresos del Evento"
                                                v-bind:properties="{
                                                                dense: true,
                                                                prefix: '$',
                                                                readonly: false,
                                                                disabled: false,
                                                                outlined: false,
                                                                clearable: false,
                                                                placeholder: '',
                                                              }"
                                                v-bind:options="{
                                                            locale: 'es-MX',
                                                            length: 11,
                                                            precision: 2,
                                                            empty: null,
                                                          }"
                                            />
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field-money
                                                v-model.number="investor_event.expense"
                                                label="Gasto del Evento"
                                                :disabled="loadingSave"
                                                v-bind:properties="{
                                                                dense: true,
                                                                prefix: '$',
                                                                readonly: false,
                                                                disabled: false,
                                                                outlined: false,
                                                                clearable: false,
                                                                placeholder: '',
                                                              }"
                                                v-bind:options="{
                                                            locale: 'es-MX',
                                                            length: 11,
                                                            precision: 2,
                                                            empty: null,
                                                          }"
                                            />
                                        </v-col>
                                        <v-col cols="12">
                                          <InputMoney
                                              v-model.number="investor_event.utility"
                                              label="Utilidad"
                                              :disabled="loadingSave"
                                              v-bind:properties="{
                                                                    dense: true,
                                                                    prefix: '$',
                                                                    readonly: false,
                                                                    disabled: false,
                                                                    outlined: false,
                                                                    clearable: false,
                                                                    placeholder: '',
                                                                  }"
                                              v-bind:options="{
                                                                locale: 'es-MX',
                                                                length: 11,
                                                                precision: 2,
                                                                empty: null,
                                                              }"
                                          />
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn :disabled="loadingSave" @click="closeDialogUpdateEvent()">
                                        Cancelar
                                    </v-btn>
                                    <v-btn :loading="loadingSave" color="primary darken-1 caption" depressed @click="saveUpdateEvent()">
                                        <v-icon>mdi-content-save</v-icon>
                                        Guardar
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            :fullscreen="$vuetify.breakpoint.smAndDown"
                            v-model="dialogProjects"
                            persistent
                            width="1000">
                            <v-card>
                                <v-toolbar dark color="primary" height="60">
                                    <v-btn
                                        class="hidden-sm-and-down"
                                        icon
                                        dark
                                        @click="closeDialogProjects()">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                    <v-toolbar-title>Asignaci&oacute;n de Proyectos</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-toolbar-items>
                                        <v-btn dark
                                               text
                                               @click="closeDialogProjects()">
                                            Cerrar
                                        </v-btn>
                                    </v-toolbar-items>
                                </v-toolbar>
                                <v-container fill-height bottom-gradient fluid class="ma-0">
                                    <v-row>
                                        <v-col>
                                            <div class="fill-height">
                                                <v-simple-table dense fixed-header height="250px">
                                                    <template v-slot:default>
                                                        <thead>
                                                        <tr>
                                                            <th class="text-left" style="width: auto;">
                                                            </th>
                                                            <th class="text-left">
                                                                Nombre del Proyecto
                                                            </th>
                                                            <th class="text-left">
                                                                Fecha de Inicio
                                                            </th>
                                                            <th class="text-left">
                                                                Monto de Inversi&oacute;n
                                                            </th>
                                                            <th class="text-left">
                                                                Inversión en Puntos
                                                            </th>
                                                            <th class="text-left">
                                                                Ingresos
                                                            </th>
                                                            <th class="text-left">
                                                                Gastos
                                                            </th>
                                                            <th class="text-left">
                                                                Utilidad
                                                            </th>
                                                            <th class="text-left" style="width:95px;">
                                                                Acciones
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr
                                                            v-for="project in editedItem.projects"
                                                            :key="project.id">
                                                            <td>
                                                                <v-avatar color="#ded06f" size="40" class="my-1">
                                                                    <v-img
                                                                        :src="project.pictures.length > 0 ?
                                                                        project.pictures[0].url :
                                                                        require('@/assets/images/no-image.jpg')">
                                                                    </v-img>
                                                                </v-avatar>
                                                            </td>
                                                            <td>{{ project.name }}</td>
                                                            <td>{{ formatDate(project.start_date) }}</td>
                                                            <td>{{ project.pivot.investment_amount | toCurrency }}</td>
                                                            <td>{{ project.pivot.investment_points }}</td>
                                                            <td>{{ project.pivot.investment_income | toCurrency }}</td>
                                                            <td>{{ project.pivot.expense | toCurrency }}</td>
                                                            <td>{{ project.pivot.utility | toCurrency }}</td>
                                                            <td>
                                                                <v-tooltip bottom>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-icon
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                            small
                                                                            class="mr-1"
                                                                            @click="editProject(project)">
                                                                            mdi-currency-usd
                                                                        </v-icon>
                                                                    </template>
                                                                    <span>Actualizar Datos</span>
                                                                </v-tooltip>
                                                                <v-tooltip bottom>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-icon
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                            small
                                                                            class="mr-1"
                                                                            @click="loadListProjectEvents(project)">
                                                                            mdi-calendar-clock
                                                                        </v-icon>
                                                                    </template>
                                                                    <span>Gestionar Eventos</span>
                                                                </v-tooltip>
                                                                <v-tooltip bottom>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-icon
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                            small
                                                                            class="mr-1"
                                                                            @click="deleteProject(project)">
                                                                            mdi-delete
                                                                        </v-icon>
                                                                    </template>
                                                                    <span>Eliminar proyecto</span>
                                                                </v-tooltip>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <div class="container mt-0 pt-0">
                                    <v-container class="container--fluid">
                                        <v-row>
                                            <v-col cols="12">
                                                <v-select
                                                    v-model="selectedProject"
                                                    :items="projectFilteredList"
                                                    clearable
                                                    :disabled="loadingSave"
                                                    item-text="name"
                                                    item-value="id"
                                                    return-object
                                                    placeholder="Seleccione un proyecto"
                                                    item-color="primary"
                                                    persistent-hint>
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                    <div class="py-1">
                                        <v-btn
                                            color="primary caption"
                                            depressed
                                            @click="registerProject()"
                                            :loading="loadingSave"
                                            :disabled="selectedProject == null">
                                            Agregar Proyecto
                                        </v-btn>
                                    </div>
                                </div>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogUpdateProject" max-width="400px" persistent>
                            <v-card>
                                <v-toolbar dark color="primary">
                                    <v-btn
                                        class="hidden-sm-and-down"
                                        icon
                                        dark
                                        @click="closeDialogUpdateProject()">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                    <v-toolbar-title>Datos de la Inversi&oacute;n</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-toolbar-items>
                                        <v-btn dark
                                               text
                                               @click="closeDialogUpdateProject()">
                                            Cerrar
                                        </v-btn>
                                    </v-toolbar-items>
                                </v-toolbar>
                                <v-container class="container--fluid mt-3">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field-money
                                                v-model.number="investor_project.investment_amount"
                                                label="Monto de Inversión"
                                                :disabled="loadingSave"
                                                v-bind:properties="{
                                                                dense: true,
                                                                prefix: '$',
                                                                readonly: false,
                                                                disabled: false,
                                                                outlined: false,
                                                                clearable: false,
                                                                placeholder: '',
                                                              }"
                                                v-bind:options="{
                                                            locale: 'es-MX',
                                                            length: 11,
                                                            precision: 2,
                                                            empty: null,
                                                          }"
                                            />
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field-money
                                                v-model.number="investor_project.investment_points"
                                                label="Inversión en Puntos"
                                                :disabled="loadingSave"
                                                v-bind:properties="{
                                                                dense: true,
                                                                prefix: '',
                                                                suffix: 'puntos',
                                                                readonly: false,
                                                                disabled: false,
                                                                outlined: false,
                                                                clearable: false,
                                                                placeholder: '',
                                                              }"
                                                v-bind:options="{
                                                            locale: 'es-MX',
                                                            length: 11,
                                                            precision: 2,
                                                            empty: null,
                                                          }"
                                            />
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field-money
                                                v-model.number="investor_project.investment_income"
                                                label="Ingresos estimados"
                                                :disabled="loadingSave"
                                                v-bind:properties="{
                                                                dense: true,
                                                                prefix: '$',
                                                                readonly: false,
                                                                disabled: false,
                                                                outlined: false,
                                                                clearable: false,
                                                                placeholder: '',
                                                              }"
                                                v-bind:options="{
                                                            locale: 'es-MX',
                                                            length: 11,
                                                            precision: 2,
                                                            empty: null,
                                                          }"
                                            />
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field-money
                                                v-model.number="investor_project.investment_expense"
                                                label="Gastos estimados"
                                                :disabled="loadingSave"
                                                v-bind:properties="{
                                                                dense: true,
                                                                prefix: '$',
                                                                readonly: false,
                                                                disabled: false,
                                                                outlined: false,
                                                                clearable: false,
                                                                placeholder: '',
                                                              }"
                                                v-bind:options="{
                                                            locale: 'es-MX',
                                                            length: 11,
                                                            precision: 2,
                                                            empty: null,
                                                          }"
                                            />
                                        </v-col>
                                      <v-col cols="12">
                                        <InputMoney
                                            v-model.number="investor_project.utility"
                                            label="Utilidad"
                                            :disabled="loadingSave"
                                            v-bind:properties="{
                                                                  dense: true,
                                                                  prefix: '$',
                                                                  readonly: false,
                                                                  disabled: false,
                                                                  outlined: false,
                                                                  clearable: false,
                                                                  placeholder: '',
                                                                }"
                                            v-bind:options="{
                                                              locale: 'es-MX',
                                                              length: 11,
                                                              precision: 2,
                                                              empty: null,
                                                            }"
                                        />
                                      </v-col>
                                    </v-row>
                                </v-container>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn :disabled="loadingSave" @click="closeDialogUpdateProject()">
                                        Cancelar
                                    </v-btn>
                                    <v-btn :loading="loadingSave" color="primary darken-1 caption" depressed @click="saveUpdateProject()">
                                        <v-icon>mdi-content-save</v-icon>
                                        Guardar
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            :fullscreen="$vuetify.breakpoint.smAndDown"
                            v-model="dialogProjectEvents"
                            persistent
                            width="1000">
                            <v-card>
                                <v-toolbar dark color="primary" height="60">
                                    <v-btn
                                        class="hidden-sm-and-down caption"
                                        icon
                                        dark
                                        text
                                        @click="closeDialogProjectEvents()">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                    <v-toolbar-title>Eventos del Proyecto</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-toolbar-items>
                                        <v-btn dark
                                               text
                                               @click="closeDialogProjectEvents()">
                                            Cerrar
                                        </v-btn>
                                    </v-toolbar-items>
                                </v-toolbar>
                                <v-container fill-height bottom-gradient fluid class="ma-0">
                                    <v-row dense>
                                        <v-col>
                                            <div class="fill-height">
                                                <v-simple-table dense fixed-header height="280px">
                                                    <template v-slot:default>
                                                        <thead>
                                                        <tr>
                                                            <th class="text-left">
                                                            </th>
                                                            <th class="text-left">
                                                                Nombre del Evento
                                                            </th>
                                                            <th class="text-left">
                                                                Fecha del Evento
                                                            </th>
                                                            <th class="text-left">
                                                                Monto de Inversi&oacute;n
                                                            </th>
                                                            <th class="text-left">
                                                                Ingreso del Evento
                                                            </th>
                                                            <th class="text-left">
                                                                Gastos del Evento
                                                            </th>
                                                            <th class="text-left">
                                                                Utilidad
                                                            </th>
                                                            <th class="text-left">
                                                                Acciones
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr
                                                            v-for="project in projecstEventsInvestorList"
                                                            :key="project.id">
                                                            <td>
                                                                <v-avatar color="#ded06f" size="40" class="my-1">
                                                                    <v-img
                                                                        :src="(project.event.image_url !== null ||
                                                                        project.event.image_url !== '') ?
                                                                        project.event.image_url :
                                                                        require('@/assets/images/user-3297.png')">
                                                                    </v-img>
                                                                </v-avatar>
                                                            </td>
                                                            <td>{{ project.event.name }}</td>
                                                            <td>{{ formatDate(project.event.start_date) }}</td>
                                                            <td>{{ project.investment_amount | toCurrency }}</td>
                                                            <td>{{ project.investment_income | toCurrency }}</td>
                                                            <td>{{ project.expense | toCurrency }}</td>
                                                            <td>{{ project.utility | toCurrency }}</td>
                                                            <td>
                                                                <v-tooltip bottom>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-icon
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                            small
                                                                            class="mr-2"
                                                                            @click="editProjectEvent(project)">
                                                                            mdi-currency-usd
                                                                        </v-icon>
                                                                    </template>
                                                                    <span>Datos de la Inversi&oacute;n</span>
                                                                </v-tooltip>
                                                                <v-tooltip bottom>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-icon
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                            small
                                                                            class="mr-2"
                                                                            @click="deleteProjectEvent(project)">
                                                                            mdi-delete
                                                                        </v-icon>
                                                                    </template>
                                                                    <span>Eliminar evento</span>
                                                                </v-tooltip>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <div class="container mt-1 pt-0">
                                    <v-container>
                                        <v-row dense>
                                            <v-col cols="12">
                                                <v-select
                                                    :disabled="loadingSave"
                                                    v-model="selectedProjectEvent"
                                                    :items="projectEventFilteredList"
                                                    clearable
                                                    item-text="name"
                                                    item-value="id"
                                                    return-object
                                                    placeholder="Seleccione un evento"
                                                    item-color="primary"
                                                    persistent-hint>
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                    <div>
                                        <div class="py-3">
                                            <v-btn
                                                :loading="loadingSave"
                                                color="primary caption"
                                                depressed
                                                @click="registerProjectEvent()"
                                                :disabled="selectedProjectEvent == null">
                                                Agregar Evento
                                            </v-btn>
                                        </div>
                                    </div>
                                </div>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogUpdateProjectEvent" max-width="400px" persistent>
                            <v-card>
                                <v-toolbar dark color="primary">
                                    <v-btn
                                        class="hidden-sm-and-down"
                                        icon
                                        dark
                                        @click="closeDialogUpdateProjectEvent()">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                    <v-toolbar-title>Actualizar datos</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-toolbar-items>
                                        <v-btn dark
                                               text
                                               @click="closeDialogUpdateProjectEvent()">
                                            Cerrar
                                        </v-btn>
                                    </v-toolbar-items>
                                </v-toolbar>
                                <v-container class="container--fluid mt-3">
                                    <v-row dense>
                                        <v-col cols="12">
                                            <v-text-field-money
                                                :disabled="loadingSave"
                                                v-model.number="project_event_investor.investment_amount"
                                                label="Inversión Total"
                                                v-bind:properties="{
                                                            dense: true,
                                                            prefix: '$',
                                                            readonly: false,
                                                            disabled: false,
                                                            outlined: false,
                                                            clearable: false,
                                                            placeholder: '',
                                                          }"
                                                v-bind:options="{
                                                        locale: 'es-MX',
                                                        length: 11,
                                                        precision: 2,
                                                        empty: null,
                                                      }"
                                            />
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field-money
                                                v-model.number="project_event_investor.investment_income"
                                                label="Ingresos del Evento"
                                                :disabled="loadingSave"
                                                v-bind:properties="{
                                                                dense: true,
                                                                prefix: '$',
                                                                readonly: false,
                                                                disabled: false,
                                                                outlined: false,
                                                                clearable: false,
                                                                placeholder: '',
                                                              }"
                                                v-bind:options="{
                                                            locale: 'es-MX',
                                                            length: 11,
                                                            precision: 2,
                                                            empty: null,
                                                          }"
                                            />
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field-money
                                                v-model.number="project_event_investor.expense"
                                                :disabled="loadingSave"
                                                label="Gasto del Evento"
                                                v-bind:properties="{
                                                            dense: true,
                                                            prefix: '$',
                                                            readonly: false,
                                                            disabled: false,
                                                            outlined: false,
                                                            clearable: false,
                                                            placeholder: '',
                                                          }"
                                                v-bind:options="{
                                                        locale: 'es-MX',
                                                        length: 11,
                                                        precision: 2,
                                                        empty: null,
                                                      }"
                                            />
                                        </v-col>
                                        <v-col cols="12">
                                            <InputMoney
                                                v-model.number="project_event_investor.utility"
                                                label="Utilidad"
                                                :disabled="loadingSave"
                                                v-bind:properties="{
                                                                    dense: true,
                                                                    prefix: '$',
                                                                    readonly: false,
                                                                    disabled: false,
                                                                    outlined: false,
                                                                    clearable: false,
                                                                    placeholder: '',
                                                                  }"
                                                v-bind:options="{
                                                                locale: 'es-MX',
                                                                length: 11,
                                                                precision: 2,
                                                                empty: null,
                                                              }"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        :disabled="loadingSave"
                                        @click="closeDialogUpdateProjectEvent()"
                                    >
                                        Cancelar
                                    </v-btn>
                                    <v-btn
                                        color="primary darken-1 caption"
                                        depressed
                                        :loading="loadingSave"
                                        @click="saveUpdateProjectEvent()">
                                        <v-icon>mdi-content-save</v-icon>
                                        Guardar
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <!--<template #item.full_name="{ item }">
                    {{ item.user.name }} {{ item.user.last_name }}
                </template>-->
                <template #item.count_events="{ item }">
                    {{ item.events_count }} <!--/{{ item.projects_events_investors_count }-->
                </template>
                <template v-slot:item.full_name="{ item }">
                    {{ item.user.name }} {{ item.user.last_name }}
                </template>
                <template v-slot:item.enabled="{ item }">
                    <v-simple-checkbox
                        v-model="item.enabled"
                        disabled
                    ></v-simple-checkbox>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-tooltip top v-if="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="mr-2"
                                @click="editItem(item)">
                                mdi-pencil
                            </v-icon>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="mr-2"
                                @click="loadListProjects(item)">
                                mdi-folder-open
                            </v-icon>
                        </template>
                        <span>Asociar Proyectos</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="mr-2"
                                @click="loadListEvents(item)">
                                mdi-calendar-clock
                            </v-icon>
                        </template>
                        <span>Asociar Eventos</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="mr-2"
                                @click="deleteItem(item)">
                                mdi-delete
                            </v-icon>
                        </template>
                        <span>Eliminar</span>
                    </v-tooltip>
                </template>
                <template v-slot:no-data>
                    <span class="text-h5">No hay Asignaciones en el listado</span>
                </template>
            </v-data-table>
            <v-snackbar
                v-model="snackBar"
                :timeout="2000">
                {{ snackText }}
                <template v-slot:action="{ attrs }">
                    <v-btn v-bind="attrs"
                           icon
                           @click="snackBar = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-snackbar>
        </v-card>
    </v-container>
</template>

<script>
    import investorService from '@/providers/InvestorService';
    import projectsService from '@/providers/ProjectsService';
    import projectsEventsService from "@/providers/ProjectsEventsService";
    import eventService from "@/providers/EventService";
    import userService from '@/providers/UsersService';
    import InputMoney from "@/components/InputMoney.vue";

    export default {
        name: "InvestorsModuleComponent",
      components: {InputMoney},
        data: () => ({
            snackBar: false,
            snackText: '',
            valid: true,
            selectUser: null,
            archived: false,
            loadingData: false,
            loadingSave: false,
            searchInvestor: '',
            dialog: false,
            dialogDelete: false,
            dialogProjects: false,
            dialogProjectEvents: false,
            dialogDeleteProject: false,
            dialogDeleteProjectEvent: false,
            dialogEvents: false,
            dialogDeleteEvent: false,
            dialogUpdateProject: false,
            dialogUpdateProjectEvent: false,
            dialogUpdateEvent: false,
            investors: [],
            usersList: [],
            usersFilteredList: [],
            currentProject: null,
            selectedProject: null,
            investor_project: {
                investment_amount: 0,
                investment_points: 0,
                investment_income: 0,
                investment_expense: 0,
                utility: 0,
            },
            selectedEvent: null,
            currentInvestorEvent: null,
            investor_event: {
                investment_amount: 0,
                investment_points: 0,
                investment_income: 0,
                utility : 0,
                expense: 0,
                perc_utility: 0
            },
            project_event_investor: {
                investment_amount: 0,
                investment_income: 0,
                expense: 0,
                utility: 0,
                perc_utility: 0
            },
            currentProjectEvent: null,
            selectedProjectEvent: null,
            projectList: [],
            projectFilteredList: [],
            currentEvent: null,
            eventList: [],
            eventProjectList: [],
            eventFilteredList: [],
            projecstEventsInvestorList: [],
            projectEventFilteredList: [],
            listUsers: [],
            listInvestor: [],
            headers: [
                {text: 'Id', value: 'id', align: ' d-none'}, // para ocultar la columna Id.
                {text: "Nombre del Inversionista", value: "full_name"},
                {text: "Cantidad de Proyectos", value: "projects_count", align: 'center', filterable: false},
                {text: "Cantidad de Eventos", value: "count_events", align: 'center', filterable: false},
                {text: 'Acciones', value: 'actions', width: 130, align: 'center', sortable: false},
            ],
            editedIndex: -1,
            editedItem: {
                id: 0,
                investment_type: '',
                investment_project: 0,
                investment_event: 0,
                user_id: 0,
                projects_count: 0,
                events_count: 0,
                user: null,
                projects: [],
                events: []
            },
            defaultItem: {
                id: 0,
                investment_type: '',
                user_id: 0,
                projects_count: 0,
                events_count: 0,
                user: null,
                projects: [],
                events: []
            },
            nameRules: [
                v => !!v || 'El Campo es obligatorio'
                // v => (v && v.length >= 10) || 'El Nombre debe tener mas de un caracter.',
            ],
        }),
        computed: {
            formTitle() {
                return this.editedIndex === -1 ? 'Nueva Asignación' : 'Editar Asignación';
            },
        },
        watch: {
            dialog(val) {
                val || this.close();
            },
            dialogDelete(val) {
                val || this.closeDelete();
            },
        },
        filters: {
            toCurrency (value) {
                if (typeof value !== "number") {
                    return value;
                }
                let formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    useGrouping: true,
                    minimumFractionDigits: 2
                });
                return formatter.format(value);
            },
            toTwoDecimal (value) {
                if (typeof value !== "number") {
                    return value;
                }
                let formatter = new Intl.NumberFormat('en-US', {
                    useGrouping: false,
                    maximumSignificantDigits: 2,
                    minimumFractionDigits: 2
                });
                return formatter.format(value);
            }
        },
        methods: {
            formatDate(value) {
                if (!value) return null;
                let theDate = value.toString().substr(0, 10);
                const [year, month, day] = theDate.split('-')
                return `${day}/${month}/${year}`;
            },
            customSearch(value, search, item) {
                return item.user.name.toLowerCase().includes(search.toLowerCase());
            },
            getItemText(item) {
                return `${item.name} ${item.last_name}`;
            },
            getInvestors() {
                this.loadingData = true;
                // Lista de Usuarios
                userService.getViewListInvestors().then(record => {
                    this.listInvestor = record.value??[];
                });
                investorService.listUsers().then(record => {
                    this.listUsers = record.value??[];
                });
                // Lista de Inversionistas
                investorService.getViewList().then(record => {
                    this.investors = record.value??[];
                });
                // Lista de Proyectos
                const archived = Number(this.archived);
                projectsService.getViewList(archived).then(record => {
                    this.projectsList = record.value??[];
                }).catch(err=>console.log(err)).finally(()=>this.loadingData = false);
            },
            newItem() {
                this.valid = false;
                this.editedItem = Object.assign({}, this.defaultItem);
                this.usersFilteredList = this.listInvestor.filter(
                    o => !this.investors.some(i => i.user.id === o.id)
                );
            },
            editItem(item) {
                this.usersList = this.listInvestor;
                this.editedIndex = this.investors.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialog = true;
            },
            deleteItem(item) {
                this.editedIndex = this.investors.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialogDelete = true;
            },
            deleteItemConfirm() {
                this.loadingSave = true;
                investorService.deleteRecord(this.editedItem.id).then(
                    response => {
                        if (response.success) {
                            this.investors.splice(this.editedIndex, 1);
                            this.snackText = 'Asignación eliminada con éxito.';
                            this.getInvestors();
                        } else {
                            this.snackText = 'Un error impidió eliminar la asignación';
                        }
                    }).catch(err => {
                    console.log('Error ejecutando API: ', err);
                    this.messageError = "Un error impidió eliminar la asignación";
                    this.dialogError = true;
                }).finally(() => {
                    this.closeDelete();
                    this.snackBar = true;
                    this.loadingSave = false;
                });
            },
            close() {
                this.dialog = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },
            closeDelete() {
                this.dialogDelete = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },
            save() {
                this.$refs.form.validate();
                if (this.$refs.form.validate(true)) {
                    this.loadingSave = true;
                    if (this.editedIndex > -1) {
                        // Actualizar el registro por el metodo PUT
                        Object.assign(this.investors[this.editedIndex], this.editedItem);
                        investorService.updateRecord(this.editedItem).then(
                            response => {
                                if (response.success) {
                                    this.getInvestors();
                                }
                            }
                        ).catch(err=>console.log(err)).finally(()=>this.loadingSave = false);
                    } else {
                        // Agrega el registro por el metodo POST
                        this.investors.push(this.editedItem);
                        investorService.addRecord(this.editedItem).then(
                            response => {
                                if (response.success) {
                                    this.getInvestors();
                                }
                            }
                        ).catch(err=>console.log(err)).finally(()=>this.loadingSave = false);
                    }
                    this.close();
                    this.snackText = 'Registro guardado con éxito.';
                    this.snackBar = true;
                }
            },
            /* Para administrar los eventos */
            loadAllEvents() {
                if (this.eventList.length === 0) {
                    eventService.getViewListWithoutProjects().then(record => {
                        this.eventList = record.value??[];
                    });
                }
            },
            loadListEvents(item) {
                this.selectedEvent = null;
                this.investor_event.investment_amount = 0;
                this.investor_event.investment_points = 0;
                this.investor_event.investment_income = 0;
                this.investor_event.expense = 0;
                this.loadAllEvents();
                investorService.getInvestor(item.user_id).then(fetch_data => {
                    this.editedItem = Object.assign({}, fetch_data.value);
                    this.loadAllEvents();
                    this.eventFilteredList = this.eventList.filter(
                        o => !this.editedItem.events.some(i => i.id === o.id));
                    if (!this.dialogEvents) this.dialogEvents = true;
                });
            },
            editEvent(item) {
                this.currentInvestorEvent = Object.assign({}, item);
                this.investor_event.investment_amount = this.currentInvestorEvent.pivot.investment_amount;
                this.investor_event.investment_points = this.currentInvestorEvent.pivot.investment_points;
                this.investor_event.expense = this.currentInvestorEvent.pivot.expense;
                this.investor_event.investment_income = this.currentInvestorEvent.pivot.investment_income;
                this.investor_event.utility = this.currentInvestorEvent.pivot.utility;
                this.$store.state.valueIntern = this.investor_event.utility;
                this.dialogUpdateEvent = true;
            },
            registerEvent() {
                let formData = {
                    investor_id: this.editedItem.id,
                    selected_event_id: this.selectedEvent.id
                }
                this.loadingSave = true;
                investorService.registerEvent(formData).then(result => {
                    if (result.success) {
                        this.loadListEvents(this.editedItem);
                        this.snackText = 'Evento agregado en la lista de evento.';
                    } else {
                        this.snackText = 'Un error impidió agregar el evento en la lista';
                    }
                }).catch(err => {
                    console.log(err);
                    this.snackText = 'Un error impidió agregar el evento en la lista';
                }).finally(() => {
                    this.snackBar = true;
                    this.loadingSave = false;
                })
            },
            saveUpdateEvent() {
                let formData = {
                    investor_id: this.currentInvestorEvent.pivot.investor_id,
                    event_id: this.currentInvestorEvent.id,
                    amount: this.investor_event.investment_amount,
                    points: this.investor_event.investment_points,
                    income: this.investor_event.investment_income,
                    expense: this.investor_event.expense,
                    utility: this.investor_event.utility==0?this.investor_event.investment_income - this.investor_event.expense:this.$store.state.numberFormat,
                    perc_utility: this.investor_event.expense*100/this.currentInvestorEvent.investment_amount/100
                }
                this.loadingSave = true;
                investorService.updateEvent(formData)
                    .then(result => {
                        if (result.success) {
                            this.loadListEvents(this.editedItem);
                            this.snackText = 'Evento del inversionista actualizado con éxito';
                        } else {
                            this.snackText = 'Un error impidió actualizar el evento del inversionista';
                        }
                    }).catch(err => {
                    console.log(err);
                    this.snackText = 'Un error impidió actualizar el evento del inversionista';
                }).finally(() => {
                    this.snackBar = true;
                    this.closeDialogUpdateEvent();
                    this.loadingSave = false;
                });
            },
            deleteEvent(item) {
                this.currentEvent = Object.assign({}, item);
                this.dialogDeleteEvent = true;
            },
            deleteItemConfirmEvent() {
                this.loadingSave = true;
                investorService.deleteEvent(this.currentEvent.pivot.investor_id, this.currentEvent.id).then(result => {
                    this.closeDialogDeleteEvent();
                    if (result.success) {
                        this.snackText = 'Evento eliminado desde la lista';
                        this.loadListEvents(this.editedItem);
                    } else {
                        this.snackText = 'Un error impidió eliminar el documento desde la lista';
                    }
                    this.dialogDeleteEvent = false;
                    this.snackBar = true;
                }).catch(err=>console.log(err)).finally(()=>this.loadingSave = false);
            },
            closeDialogDeleteEvent() {
                this.dialogDeleteEvent = false;
            },
            closeDialogEvents() {
                this.dialogEvents = false;
                this.getInvestors();
            },
            closeDialogUpdateEvent() {
                this.dialogUpdateEvent = false;
            },
            /* Para administrar los proyectos */
            loadAllProjects() {
                if (this.projectList.length === 0) {
                    this.loadingData = true;
                    projectsService.getAllRecords().then(record => {
                        this.projectList = record.value??[];
                    }).catch(err=>console.log(err)).finally(()=>this.loadingData = false);
                }
            },
            loadListProjects(item) {
                this.selectedProject = null;
                this.investor_project.investment_amount = 0;
                this.investor_project.investment_points = 0;
                this.investor_project.investment_income = 0;
                this.investor_project.investment_expense = 0;
                this.investor_project.utility = 0;
                this.loadAllProjects();
                investorService.getInvestor(item.user_id).then(fetch_data => {
                    this.editedItem = Object.assign({}, fetch_data.value);
                    this.loadAllProjects();
                    // Este método es para cargar en projectFilteredList los items del array projectList que no se
                    // encuentran dentro del array editedItem.projects
                    this.projectFilteredList = this.projectList.filter(
                        o => !this.editedItem.projects.some(i => i.id === o.id));
                    if (!this.dialogProjects) this.dialogProjects = true;
                });
            },
            registerProject() {
                let formData = {
                    investor_id: this.editedItem.id,
                    project_id: this.selectedProject.id
                };
                this.loadingSave = true;
                investorService.registerProject(formData).then(result => {
                    if (result.success) {
                        this.loadListProjects(this.editedItem);
                        this.snackText = 'Projecto agregado en la lista de proyectos.';
                    } else {
                        this.snackText = 'Un error impidió agregar el proyecto en la lista';
                    }
                }).catch(err => {
                    console.log(err);
                    this.snackText = 'Un error impidió agregar el proyecto en la lista';
                }).finally(() => {
                    this.snackBar = true;
                    this.loadingSave = false;
                });
            },
            editProject(item) {
                this.currentProject = Object.assign({}, item);
                this.investor_project.investment_amount = this.currentProject.pivot.investment_amount;
                this.investor_project.investment_points = this.currentProject.pivot.investment_points;
                this.investor_project.investment_income = this.currentProject.pivot.investment_income;
                this.investor_project.investment_expense = this.currentProject.pivot.expense;
                this.investor_project.utility = this.currentProject.pivot.utility;
                this.$store.state.valueIntern = this.investor_project.utility;
                this.dialogUpdateProject = true;
            },
            saveUpdateProject() {
                let formData = {
                    investor_id: this.currentProject.pivot.investor_id,
                    project_id: this.currentProject.id,
                    amount: this.investor_project.investment_amount,
                    points: this.investor_project.investment_points,
                    incomes: this.investor_project.investment_income,
                    expenses: this.investor_project.investment_expense,
                    utility: this.investor_project.utility==0?this.investor_project.investment_income - this.investor_project.investment_expense:this.$store.state.numberFormat,
                };
                this.loadingSave = true;
                investorService.updateProject(formData)
                    .then(result => {
                    if (result.success) {
                        this.loadListProjects(this.editedItem);
                        this.snackText = 'Projecto actualizado en la lista de proyectos.';
                    } else {
                        this.snackText = 'Un error impidió actualizar el proyecto en la lista';
                    }
                }).catch(err => {
                    console.log(err);
                    this.snackText = 'Un error impidió actualizar el proyecto en la lista';
                }).finally(() => {
                    this.snackBar = true;
                    this.closeDialogUpdateProject();
                    this.loadingSave = false;
                })
            },
            deleteProject(item) {
                this.currentProject = Object.assign({}, item);
                this.dialogDeleteProject = true;
            },
            deleteItemConfirmProject() {
                this.loadingSave = true;
                investorService.deleteProject(this.currentProject.pivot.investor_id, this.currentProject.id).then(result => {
                    this.closeDialogDeleteProject();
                    if (result.success) {
                        this.snackText = 'Proyecto eliminado desde la lista';
                        this.loadListProjects(this.editedItem);
                    } else {
                        this.snackText = 'Un error impidió eliminar el proyecto desde la lista';
                    }
                    this.dialogDeleteProject = false;
                    this.snackBar = true;
                }).catch(err=>console.log(err)).finally(()=>this.loadingSave = false);
            },
            closeDialogUpdateProject() {
                this.dialogUpdateProject = false;
            },
            closeDialogDeleteProject() {
                this.dialogDeleteProject = false;
            },
            closeDialogProjects() {
                this.dialogProjects = false;
                this.getInvestors();
            },
            closeDialogDeleteProjectEvent() {
                this.dialogDeleteProjectEvent = false;
            },
            closeDialogProjectEvents() {
                this.dialogProjectEvents = false;
            },
            loadProjectsEvents() {
                if (this.eventProjectList.length === 0) {
                    eventService.getViewListEventsProjects(this.currentProject.id).then(record => {
                        this.eventProjectList = record.value;
                    });
                }
            },
            loadListProjectEvents(item) {
                this.selectedProjectEvent = null;
                this.project_event_investor.investment_amount = 0;
                this.project_event_investor.investment_income = 0;
                this.project_event_investor.expense = 0;
                this.project_event_investor.utility = 0;
                this.currentProject = Object.assign({}, item);
                this.loadProjectsEvents();
                projectsEventsService.getViewListEvents(this.currentProject.pivot.id).then(fetch_data => {
                    this.projecstEventsInvestorList = fetch_data.value;
                    this.loadProjectsEvents();
                    // Este método es para cargar en projectFilteredList los items del array projectList que no se
                    // encuentran dentro del array editedItem.projects
                    this.projectEventFilteredList = this.eventProjectList.filter(
                        o => !this.projecstEventsInvestorList.some(i => i.event_id === o.id));
                    if (!this.dialogProjectEvents) this.dialogProjectEvents = true;
                });
            },
            registerProjectEvent() {
                let formData = {
                    project_id: this.currentProject.id,
                    event_id: this.selectedProjectEvent.id,
                    project_investor_id: this.currentProject.pivot.id,
                }
                this.loadingSave = true;
                projectsEventsService.addRecord(formData).then(result => {
                    if (result.success) {
                        projectsEventsService.getViewListEvents(this.currentProject.pivot.id).then(fetch_data => {
                            this.projecstEventsInvestorList = fetch_data.value;
                            this.selectedProjectEvent = null;
                            this.project_event_investor.investment_amount = 0;
                            this.project_event_investor.investment_income = 0;
                            this.project_event_investor.expense = 0;
                            this.project_event_investor.utility = 0;
                            this.projectEventFilteredList = this.eventProjectList.filter(
                                    o => !this.projecstEventsInvestorList.some(i => i.event_id === o.id));
                        });
                        this.snackText = 'Evento agregado en la lista de proyecto.';
                    } else {
                        this.snackText = 'Un error impidió agregar el evento en la lista de proyecto.';
                    }
                }).catch(err => {
                    console.log(err);
                    this.snackText = 'Un error impidió agregar el evento en la lista de proyecto.';
                }).finally(() => {
                    this.snackBar = true;
                    this.loadingSave = false;
                });
                return true;
            },
            deleteProjectEvent(item) {
                this.currentProjectEvent = Object.assign({}, item);
                this.dialogDeleteProjectEvent = true;
            },
            deleteItemConfirmProjectEvent() {
                this.loadingSave = true;
                // console.log('Id Evento del proyecto a borrar: ', this.currentProjectEvent.id);
                projectsEventsService.deleteRecord(this.currentProjectEvent.id).then(result => {
                    this.closeDialogDeleteProjectEvent();
                    if (result.success) {
                        this.snackText = 'Evento eliminado desde la lista del Proyecto';
                        projectsEventsService.getViewListEvents(this.currentProject.pivot.id).then(fetch_data => {
                            this.projecstEventsInvestorList = fetch_data.value;
                            // Este método es para cargar en projectFilteredList los items del array projectList que no se
                            // encuentran dentro del array editedItem.projects
                            this.projectEventFilteredList =
                                this.eventProjectList.filter(
                                    o => this.projecstEventsInvestorList.some(i => i.id === o.id));
                        });
                    } else {
                        this.snackText = 'Un error impidió eliminar el Evento proyecto desde la lista del Proyecto';
                    }
                    this.dialogDeleteProjectEvent = false;
                    this.snackBar = true;
                }).catch(err=>console.log(err)).finally(()=>this.loadingSave = false);
            },
            editProjectEvent(item) {
                this.currentProjectEvent = Object.assign({}, item);
                this.project_event_investor.investment_amount = this.currentProjectEvent.investment_amount;
                this.project_event_investor.investment_income = this.currentProjectEvent.investment_income;
                this.project_event_investor.expense = this.currentProjectEvent.expense;
                this.project_event_investor.utility = this.currentProjectEvent.utility;
                this.$store.state.valueIntern = this.project_event_investor.utility;
                this.dialogUpdateProjectEvent = true;
            },
            saveUpdateProjectEvent() {
                let formData = {
                    id: this.currentProjectEvent.id,
                    amount: this.project_event_investor.investment_amount,
                    income: this.project_event_investor.investment_income,
                    expense: this.project_event_investor.expense,
                    utility: this.project_event_investor.utility==0?this.project_event_investor.investment_income - this.project_event_investor.expense:this.$store.state.numberFormat,
                    perc_utility: this.project_event_investor.expense*100/this.currentProject.pivot.investment_amount/100
                }
                this.loadingSave = true;
                projectsEventsService.updateRecord(formData).then(result => {
                    if (result.success) {
                        projectsEventsService.getViewListEvents(this.currentProject.pivot.id).then(fetch_data => {
                            this.projecstEventsInvestorList = fetch_data.value;
                            this.loadProjectsEvents();
                        });
                        this.snackText = 'Evento agregado en la lista de proyecto.';
                    } else {
                        this.snackText = 'Un error impidió agregar el evento en la lista de proyecto.';
                    }
                }).catch(err => {
                    console.log(err);
                    this.snackText = 'Un error impidió agregar el evento en la lista de proyecto.';
                }).finally(() => {
                    this.snackBar = true;
                    this.closeDialogUpdateProjectEvent();
                    this.loadingSave = false;
                });
            },
            closeDialogUpdateProjectEvent() {
                this.dialogUpdateProjectEvent = false;
            }
        },
        mounted() {
            this.investors = [];
            this.getInvestors();
        }
    }
</script>

<style>
    .v-btn {
        text-transform: none !important;
    }

    tspan{
      font-family: inherit !important;
      font-size: 10px !important;
    }
</style>