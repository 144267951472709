/***********************************************/
// Capa de Servicios para el modulo de Proyectos

import axios from "axios";
import store from "@/store/store.js";

export default new class {

    #baseUrl = null;
    errormsg = '';
    message = null;
    header = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        }
    };

    constructor() {
        // Inicializar campos y propiedades.
        this.#baseUrl = store.getters.getBaseURL;
    }

    async getAllRecords() {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.get(this.#baseUrl + "/events", this.header);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getData() {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.get(this.#baseUrl + "/view/events", this.header);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getViewList() {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.get(`${this.#baseUrl}/view-list/events`, this.header);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getViewListWithoutProjects() {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.get(`${this.#baseUrl}/view-list-without-projects/events`, this.header);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getViewListEventsProjects(project_id) {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.get(`${this.#baseUrl}/view-list-events-projects/events?project_id=${project_id}`, this.header);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getRecord(id) {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.get(`${this.#baseUrl}/events/${id}`, this.header);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async addRecord(formData, imageFile) {
        try {
            let recordData = new FormData();
            recordData.append("id", formData.id);
            recordData.append("name", formData.name);
            recordData.append("description", formData.description);
            recordData.append("location", formData.location);
            recordData.append("map_lat", formData.map_lat);
            recordData.append("map_lng", formData.map_lng);
            recordData.append("start_date", formData.start_date);
            recordData.append("finish_date", formData.finish_date);
            recordData.append("investment_amount", formData.investment_amount);
            recordData.append("investment_income", formData.investment_income);
            recordData.append("expense", formData.expense);
            let utility = (formData.investment_income - formData.expense);
            recordData.append("utility", utility.toString());
            recordData.append("image_url", formData.image_url);
            recordData.append("youtube_video", formData.youtube_video);
            recordData.append("active", formData.active);
            recordData.append("status", formData.status);
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.post(this.#baseUrl + "/events", recordData, this.header);
            if (response.data.success) {
                let data = response.data;
                let record_id = data.id;
                this.uploadImage(record_id, imageFile);
                return data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    // Pushes posts to the server when called.
    async updateRecord(formData, imageFile) {
        try {
            let recordData = new FormData();
            recordData.append("id", formData.id);
            recordData.append("name", formData.name);
            recordData.append("description", formData.description);
            recordData.append("location", formData.location);
            recordData.append("map_lat", formData.map_lat);
            recordData.append("map_lng", formData.map_lng);
            recordData.append("start_date", formData.start_date);
            recordData.append("finish_date", formData.finish_date);
            recordData.append("investment_amount", formData.investment_amount);
            recordData.append("investment_income", formData.investment_income);
            recordData.append("expense", formData.expense);
            let utility = (formData.investment_income - formData.expense);
            recordData.append("utility", utility.toString());
            recordData.append("image_url", formData.image_url);
            recordData.append("youtube_video", formData.youtube_video);
            recordData.append("active", formData.active);
            recordData.append("status", formData.status);
            recordData.append('_method', 'put');
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.post(`${this.#baseUrl}/events/${formData.id}`, recordData, this.header);
            if (response.data.success) {
                let data = response.data;
                let record_id = data.id;
                this.uploadImage(record_id, imageFile);
                return data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async deleteRecord(id) {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.delete(`${this.#baseUrl}/events/${id}`);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    uploadImage(record_id, imageFile) {
        if (imageFile) {
            let formData = new FormData();
            formData.append("record_id", record_id);
            formData.append("file", imageFile);
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            axios.post(this.#baseUrl + '/upload-image/events', formData, this.header)
                .then(response => {
                    return response.data;
                })
                /* handle error */
                .catch(error => {
                        return this.handleError(error);
                    }
                );
        }
    }

    async uploadDocument(event_id, document_id, pdfFile) {
        if (pdfFile) {
            try {
                let formData = new FormData();
                formData.append("event_id", event_id);
                formData.append("document_id", document_id);
                formData.append("file", pdfFile);
                this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
                let response = await axios.post(this.#baseUrl + '/upload-document/events', formData, this.header);
                if (response.data.success) {
                    return response.data;
                } else {
                    return null;
                }
            } catch (error) {
                return this.handleError(error);
            }
        }
    }

    async deleteDocument(event_id, document_id) {
        try {
            if (document_id) {
                let formData = new FormData();
                formData.append("event_id", event_id);
                formData.append("document_id", document_id);
                this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
                let response = await axios.post(this.#baseUrl + '/delete-document/events', formData, this.header);
                if (response.data.success) {
                    return response.data;
                } else {
                    return null;
                }
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    handleError(error) {
        let valueError;
        if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            valueError = error.response.data.messaje;
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            valueError = error.request.data;
        } else {
            // Something happened in setting up the request and triggered an Error
            valueError = error.message;
        }
        console.error('valueError: ', valueError);
        return valueError;
    }

}