/***********************************************/
// Capa de Servicios para el modulo de Proyectos

import axios from "axios";
import store from "@/store/store.js";

export default new class {
    
    #baseUrl = null;
    errormsg = '';
    message = null;
    header = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        }
    };
    
    constructor() {
        // Inicializar campos y propiedades.
        this.#baseUrl = store.getters.getBaseURL;
    }
    
    async getAllRecords() {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.get(this.#baseUrl + "/projects", this.header);
            // console.log('response: ', response);
            if (response.data.success) {
                // console.log("response.data:", response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }
    
    async getData() {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.get(this.#baseUrl + "/view/projects", this.header);
            if (response.data.success) {
                // console.log("records:", response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }
    
    async getViewList(archived) {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.get(`${this.#baseUrl}/view-list/projects?archived=${archived}`, this.header);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }
    
    async getViewListAdvance(project_id) {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.get(`${this.#baseUrl}/view-list-advance/projects?project_id=${project_id}`, this.header);
            // console.log('response: ', response);
            if (response.data.success) {
                // console.log("data:", response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }
    
    async listProjects() {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.get(this.#baseUrl + "/section-list/projects", this.header);
            if (response.data.success) {
                // console.log("records:", response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }
    
    async listFavorite() {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.get(this.#baseUrl + "/list-favorites/pictures", this.header);
            if (response.data.success) {
                // console.log("records:", response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }
    
    async filterProjects(status) {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.get(`${this.#baseUrl}/filter/projects?status=${status}`, this.header);
            if (response.data.success) {
                // console.log("records:", response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }
    
    async getRecord(id) {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.get(`${this.#baseUrl}/projects/${id}`, this.header);
            if (response.data.success) {
                // console.log("records:", response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }
    
    pruebaArray() {
        let objeto1 = { id: 1, name: 'Ramon', post: 'La Habana'};
        let objeto2 = { id: 2, name: 'Ernesto', post: 'Santiago'};
        let objeto3 = { id: 3, name: 'Rene', post: 'Guantanamo'};
        let array_objetos = [objeto1, objeto2, objeto3];
        /*let data = {
            id: formData.id,
            name: formData.name,
            pictures: array_objetos
        };*/
        axios.post(this.#baseUrl + '/test-array/projects', array_objetos)
            .then(response => {
                // console.log('Respuesta: ', response.data);
                return response.data;
            })
            /* handle error */
            .catch(error => {
                    return this.handleError(error);
                }
            );
    }
    
    async addRecord(formData) {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let recordData = new FormData();
            recordData.append("id", formData.id);
            recordData.append("name", formData.name);
            recordData.append("description", formData.description);
            recordData.append("location", formData.location);
            recordData.append("map_lat", formData.map_lat);
            recordData.append("map_lng", formData.map_lng);
            recordData.append("start_date", formData.start_date);
            recordData.append("finish_date", formData.finish_date);
            recordData.append("investment", formData.investment);
            // recordData.append("investment_amount", formData.investment_amount);
            recordData.append("project_participation", formData.project_participation);
            // recordData.append("utilities", formData.utilities);
            // recordData.append("return_percentage", formData.return_percentage);
            recordData.append("expected_return_investment", formData.expected_return_investment);
            recordData.append("youtube_video", formData.youtube_video);
            recordData.append("active", formData.active);
            recordData.append("status", formData.status);
            let response = await axios.post(this.#baseUrl + "/projects", recordData, this.header);
            if (response.data.success) {
                // console.log('Respuesta: ', response.data);
                let data = response.data;
                // let project_id = data.id;
                return data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }
    // https://www.youtube.com/watch?v=kBJS5priXME ==> Condominio
    // https://www.youtube.com/watch?v=LGYndbxD4R8
    // https://www.youtube.com/watch?v=VApEjG1ocfs
    // https://www.youtube.com/watch?v=OAl5NP6BPbE
    
    // Pushes posts to the server when called.
    async updateRecord(formData) {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let recordData = new FormData();
            recordData.append("id", formData.id);
            recordData.append("name", formData.name);
            recordData.append("description", formData.description);
            recordData.append("location", formData.location);
            recordData.append("map_lat", formData.map_lat);
            recordData.append("map_lng", formData.map_lng);
            recordData.append("start_date", formData.start_date);
            recordData.append("finish_date", formData.finish_date);
            recordData.append("investment", formData.investment);
            // recordData.append("investment_amount", formData.investment_amount);
            recordData.append("project_participation", formData.project_participation);
            // recordData.append("utilities", formData.utilities);
            // recordData.append("return_percentage", formData.return_percentage);
            recordData.append("expected_return_investment", formData.expected_return_investment);
            recordData.append("youtube_video", formData.youtube_video);
            recordData.append("active", formData.active);
            recordData.append("status", formData.status);
            recordData.append('_method', 'put');
            // console.log('Record-Data: ', recordData);
            let response = await axios.post(`${this.#baseUrl}/projects/${formData.id}`, recordData, this.header);
            if (response.data.success) {
                // console.log('Respuesta: ', response.data);
                let data = response.data;
                // let project_id = data.id;
                return data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async archiveRecord(project_id, archived) {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let formData = new FormData();
            formData.append("project_id", project_id);
            formData.append("archived", archived);
            let response = await axios.post(this.#baseUrl + '/archive/projects', formData, this.header);
            if (response.data.success) {
                // console.log('Respuesta: ', response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }
    
    async deleteRecord(id) {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.delete(`${this.#baseUrl}/projects/${id}`, this.header);
            if (response.data.success) {
                console.log('Respuesta.data: ', response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }
    
    async uploadImage(project_id, picture_id, imageFile) {
        if (imageFile) {
            try {
                this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
                let formData = new FormData();
                formData.append("project_id", project_id);
                formData.append("picture_id", picture_id);
                formData.append("file", imageFile);
                let response = await axios.post(this.#baseUrl + '/upload-image/projects', formData, this.header);
                if (response.data.success) {
                    // console.log('Respuesta: ', response.data);
                    return response.data;
                } else {
                    return null;
                }
            } catch (error) {
                return this.handleError(error);
            }
        }
    }
    
    async tagImage(project_id, picture_id, tag_value) {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let formData = new FormData();
            formData.append("project_id", project_id);
            formData.append("picture_id", picture_id);
            formData.append("tag_category", tag_value);
            let response = await axios.post(this.#baseUrl + '/tag-image/projects', formData, this.header);
            if (response.data.success) {
                // console.log('Respuesta: ', response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }
    
    async markAsFavorite(project_id, picture_id) {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let formData = new FormData();
            formData.append("project_id", project_id);
            formData.append("picture_id", picture_id);
            let response = await axios.post(this.#baseUrl + '/mark-favorite/projects', formData, this.header);
            if (response.data.success) {
                // console.log('Respuesta: ', response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }
    
    async deleteImage(project_id, picture_id) {
        try {
            if (picture_id) {
                this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
                let formData = new FormData();
                formData.append("project_id", project_id);
                formData.append("picture_id", picture_id);
                let response = await axios.post(this.#baseUrl + '/delete-image/projects', formData, this.header);
                if (response.data.success) {
                    return response.data;
                } else {
                    return null;
                }
            }
        } catch (error) {
            return this.handleError(error);
        }
    }
    
    async uploadDocument(project_id, document_id, pdfFile) {
        if (pdfFile) {
            try {
                this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
                let formData = new FormData();
                formData.append("project_id", project_id);
                formData.append("document_id", document_id);
                formData.append("file", pdfFile);
                let response = await axios.post(this.#baseUrl + '/upload-document/projects', formData, this.header);
                if (response.data.success) {
                    // console.log('Respuesta: ', response.data);
                    return response.data;
                } else {
                    return null;
                }
            } catch (error) {
                return this.handleError(error);
            }
        }
    }

    async deleteDocument(project_id, document_id) {
        try {
            if (document_id) {
                this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
                let formData = new FormData();
                formData.append("project_id", project_id);
                formData.append("document_id", document_id);
                let response = await axios.post(this.#baseUrl + '/delete-document/projects', formData, this.header);
                if (response.data.success) {
                    return response.data;
                } else {
                    return null;
                }
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async registerEvent(project_id, event_id) {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let formData = new FormData();
            formData.append("project_id", project_id);
            formData.append("event_id", event_id);
            let response = await axios.post(this.#baseUrl + '/register-event/projects', formData, this.header);
            if (response.data.success) {
                console.log('Respuesta: ', response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async deleteEvent(project_id, event_id) {
        try {
            if (event_id) {
                this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
                let formData = new FormData();
                formData.append("project_id", project_id);
                formData.append("event_id", event_id);
                let response = await axios.post(this.#baseUrl + '/delete-event/projects', formData, this.header);
                if (response.data.success) {
                    return response.data;
                } else {
                    return null;
                }
            }
        } catch (error) {
            return this.handleError(error);
        }
    }
    
    async sendEmail(project_id, email) {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.get(`${this.#baseUrl}/send-email/projects?id=${project_id}&email=${email}`, this.header);
            // console.log('Respuesta: ', response);
            if (response.data.success) {
                // console.log('Respuesta: ', response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            let result;
            result = this.handleError(error);
            return result;
        }
    }
    
    handleError(error) {
        let valueError;
        if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            valueError = error.response.data.messaje;
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            valueError = error.request.data;
        } else {
            // Something happened in setting up the request and triggered an Error
            valueError = error.message;
        }
        console.error('valueError: ', valueError);
        return valueError;
    }
    
}